<template>
  <modal-component
    id="team-create"
    title="Añadir equipo"
    ok-title="Añadir equipo"
    size="lg"
    @confirmed="onCreateTeam"
    @show="onShow()"
  >
  <validation-observer ref="form" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <validation-provider
              vid="name"
              name="nombre"
              rules="required|max:255"
              v-slot="validationContext"
            >
              <b-form-group id="name" label-for="input-name">
                <b-form-input
                  id="name"
                  name="name"
                  :state="getValidationState(validationContext)"
                  placeholder="Nombre del equipo"
                  v-model="name"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </validation-observer>
  </modal-component>
</template>

<script>
import { mapMutations } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'

export default {
  name: 'CreateTeamModal',
  components: { ModalComponent },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    ...mapMutations('project', ['createTeam']),
    onCreateTeam (modalId) {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.createTeam(this.name)
        this.$nextTick(() => {
          this.$bvModal.hide(modalId)
        })
      }
    },
    onShow () {
      this.name = ''
    }
  }
}
</script>
