<template>
  <section class="grouped">
    <div class="header">
      <header>
        {{ team.name }}
      </header>
      <div class="header-buttons">
        <b-button
          v-if="!team.groupLink"
          class="float-right d-inline-block"
          variant="outline"
          v-b-modal="'telegram-modal'"
        >
          <img :src="require('@/assets/icons/telegram-logo-disabled.svg')" alt="telegram">
        </b-button>
        <b-button
          v-else
          class="float-right d-inline-block group-link"
          variant="outline"
        >
          <a :href="team.groupLink"
             target="_blank">
            <img :src="require('@/assets/icons/telegram-logo.svg')" alt="telegram">
          </a>
        </b-button>
        <b-button
          v-if="hasAdminPermission"
          v-b-modal="team.name"
          class="float-right d-inline-block p-0"
          variant="outline">
          <img :src="require('@/assets/icons/create-icon.svg')" alt="create">
        </b-button>
      </div>
    </div>
    <div v-if="team.members.length === 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <EmployeeBadge
        v-for="employee in team.members" :key="employee.id"
        :employee="employee"
        :dismissible="hasAdminPermission"
        @remove="remove"
      />
    </div>
    <EmployeeSelectorModal
      :id="team.name"
      subtitle="Selecciona las personas a añadir al equipo"
      :loadedMembers="team.members"
      :deleteButton=true
      @confirmed="add"
      @delete="deleteTeam"
      @onNewEmployee="onNewEmployee"
    />
    <TelegramGroupFormModal
      :id="'telegram-modal'"
      :form_prop="team.groupLink"
      @onSetGroupLink="onSetGroupLink"
      :teamName="team.name"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import EmployeeBadge from '@/components/Business/Project/Form/Employee/EmployeeBadge/EmployeeBadge.vue'
import EmployeeSelectorModal
from '@/components/Business/Project/Form/Employee/EmployeeSelector/EmployeeSelectorModal.vue'
import TelegramGroupFormModal from '@/components/Business/Project/Form/TelegramGroup/TelegramGroupFormModal'

export default {
  name: 'ListTeamMembers',
  components: {
    TelegramGroupFormModal,
    EmployeeBadge,
    EmployeeSelectorModal
  },
  props: {
    teamName: {
      type: String,
      required: true
    },
    hasAdminPermission: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({ project: 'project/current' }),
    team () {
      return this.project.teams.find(t => t.name === this.teamName)
    }
  },
  methods: {
    ...mapMutations('project', ['updateTeam', 'removeTeam', 'setTeamGroupLink']),
    add (members) {
      this.updateTeam({ name: this.teamName, members: members })
    },
    remove (id) {
      const members = this.team.members.filter(member => member.id !== id)
      this.updateTeam({ name: this.teamName, members: members })
    },
    onNewEmployee (modalId) {
      this.$emit('onNewEmployee', modalId)
    },
    deleteTeam (teamName) {
      this.removeTeam(teamName)
    },
    onSetGroupLink (groupLink) {
      this.setTeamGroupLink({ name: this.teamName, groupLink: groupLink })
    }
  }
}
</script>

<style lang="less" scoped>
@import "listTeamMembers";
</style>
