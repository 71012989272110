<template>
  <modal-component
    id="hardware-selector"
    title="Equipamiento"
    ok-title="Guardar cambios"
    size="lg"
    @confirmed="onSaveChanges"
    @show="onShow()"
  >
    <b-row>
      <b-col>
        <header>
          Selecciona el hardware a añadir al proyecto
        </header>
      </b-col>
    </b-row>
    <b-row class="search-box">
      <b-col class="d-flex justify-content-between align-items-center" lg="7">
        <b-input-group>
          <b-form-input
            id="name"
            name="name"
            placeholder="Escribe el nombre para buscar"
            v-model="filterText"
          ></b-form-input>
          <b-input-group-append is-text>
            <b-icon-search />
          </b-input-group-append>
        </b-input-group>
        <b-button
          size="sm"
          variant="primary"
          class="invite-employee"
          @click="$emit('onNewHardware', id)"
        >
          Añadir nuevo
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <section class="employee-list">
          <b-row class="title">
            <b-col cols="1"></b-col>
            <b-col>Hardware</b-col>
            <b-col>Marca</b-col>
            <b-col>Interno/Externo</b-col>
            <b-col>Dueño</b-col>
          </b-row>
          <b-row v-if="!optionsList || optionsList.length == 0">
            <b-col class="loading">
              No tienes hardware
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-container>
                <b-row v-for="hardware in optionsList" :key="hardware.id" class="project-row align-items-center">
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="hardware.selected"
                      :value="true"
                      :unchecked-value="false"
                      class="avify-checkbox filter"
                    ></b-form-checkbox>
                  </b-col>
                  <b-col>
                    {{ hardware.name }}
                  </b-col>
                  <b-col>
                    {{ hardware.brand.name }}
                  </b-col>
                  <b-col>
                    {{ hardware.source }}
                  </b-col>
                  <b-col>
                    {{ hardware.owner }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import { BIconSearch } from 'bootstrap-vue'

export default {
  name: 'HardwareSelectorModal',
  components: { ModalComponent, BIconSearch },
  props: {
    loadedMembers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filterText: '',
      membersList: []
    }
  },
  computed: {
    ...mapGetters({ hardwarePool: 'hardware/all', externalHardwarePool: 'project/allExternalHardware' }),
    optionsList () {
      if (this.filterText === '') {
        return this.membersList
      }
      return this.membersList.filter(e => {
        return e.name.toLowerCase().includes(this.name.toLowerCase()) ||
          e.brand.name.toLowerCase().includes(this.name.toLowerCase())
      })
    }
  },
  methods: {
    onShow () {
      this.filterText = ''
      this.membersList = this.selectMembers(this.hardwarePool)
      this.membersList.forEach(member => {
        member.source = 'Interno'
        member.owner = 'Propio'
      })
      const externalHardware = this.selectMembers(this.externalHardwarePool)
      externalHardware.forEach(member => {
        member.source = 'Externo'
        member.owner = 'Técnico externo'
      })
      this.membersList = [...this.membersList, ...externalHardware]
    },
    selectMembers (list) {
      return list.map(member => {
        const isSelected = !!this.loadedMembers.find(lm => lm.id === member.id)
        member.selected = isSelected
        return member
      })
    },
    onSaveChanges (modalId) {
      const selected = this.membersList
        .filter(e => !!e.selected)
        .map(e => {
          delete e.selected
          return e
        })
      this.$emit('confirmed', selected)
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'hardwareSelectorModal';
</style>
