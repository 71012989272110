<template>
  <span class="hardware-badge">
    <b-badge variant="avify" pill>
      {{ hardware.name }}
      <span
        v-if="dismissible"
        @click="$emit('remove', hardware.id)"
        class="dismiss-button"
      >
        &times;
      </span>
    </b-badge>
  </span>
</template>

<script>
export default {
  name: 'HardwareBadge',
  props: {
    hardware: {
      type: Object,
      required: true
    },
    dismissible: {
      type: Boolean
    }
  }
}
</script>

<style lang="less">
@import 'hardwareBadge.less';
</style>
