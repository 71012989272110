import { render, staticRenderFns } from "./CreateTeamModal.vue?vue&type=template&id=f50704d8&"
import script from "./CreateTeamModal.vue?vue&type=script&lang=js&"
export * from "./CreateTeamModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports