<template>
  <modal-component
    id="upload-project-doc"
    title="Subir documento"
    ok-title="Subir documento"
    size="lg"
    @confirmed="onDocumentSelected"
    @show="onShow()"
  >
  <validation-observer ref="form" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <validation-provider
              vid="description"
              name="description"
              rules="required|max:255"
              v-slot="validationContext"
            >
              <b-form-group id="description" label-for="input-description">
                <b-form-input
                  id="description"
                  name="description"
                  :state="getValidationState(validationContext)"
                  placeholder="Escribe la descripción del documento"
                  v-model="description"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Haz click o arrastra para añadir el documento..."
              drop-placeholder="Arrastra el documento aqui..."
            ></b-form-file>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </validation-observer>
  </modal-component>
</template>

<script>
import { mapMutations } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'

export default {
  name: 'ProjectDocSelectorModal',
  components: { ModalComponent },
  data () {
    return {
      description: null,
      file: null
    }
  },
  methods: {
    ...mapMutations('project', ['addProjectDoc']),
    onDocumentSelected (modalId) {
      const isFormValid = this.$refs.form.validate()
      if (!isFormValid) {
        return
      }
      if (this.description && this.file) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const base64 = event.target.result
          const [, data] = base64.split(',')
          const doc = {
            name: this.file.name,
            description: this.description,
            file: data,
            mimeType: this.file.type,
            extension: this.file.name.split('.').pop()
          }
          this.addProjectDoc(doc)
        }
        reader.readAsDataURL(this.file)
        this.$nextTick(() => {
          this.$bvModal.hide(modalId)
        })
      }
    },
    onShow () {
      this.description = null
    }
  }
}
</script>

<style lang="less" scoped>
@import "projectDocSelectorModal";
</style>
