<template>
  <section class="grouped">
      <b-row>
        <b-col class="title">
          <span>
            Documentos
          </span>
        </b-col>
      </b-row>
    <div v-if="project.docs.length == 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <b-row class="font-weight-bold">
        <b-col cols="3">
          <span>
            Descripción
          </span>
        </b-col>
        <b-col cols="3">
          <span>
            Nombre
          </span>
        </b-col>
        <b-col cols="2">
          <span>
            Subido por
          </span>
        </b-col>
        <b-col cols="2">
          <span>
            Fecha
          </span>
        </b-col>
        <b-col cols="2">
        </b-col>
      </b-row>
      <b-row v-for="element in project.docs" :key="element.id">
        <b-col cols="3">
          <span>
            {{ element.description }}
          </span>
        </b-col>
        <b-col cols="3">
          <span>
            {{ element.name }}
          </span>
        </b-col>
        <b-col cols="2">
          <span v-if="element.owner">
            {{ element.owner.firstName }}
          </span>
        </b-col>
        <b-col cols="2">
          <span>
            {{ element.uploadedAt | moment }}
          </span>
        </b-col>
        <b-col cols="2">
          <a
            class="float-right d-inline-block p-0"
            :href="baseUrl + element.path + '/' +element.file"
            :download="element.name"
            target="_blank"
          >
            <img :src="require('@/assets/icons/download.svg')">
          </a>
          <b-button
            :disabled="!hasAdminPermission"
            @click="remove(element.id)"
            class="float-right d-inline-block p-0"
            variant="outline">
            <img :src="require('@/assets/icons/trash-2.svg')">
          </b-button>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'ListProjectDoc',
  props: {
    hasAdminPermission: {
      type: Boolean
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  computed: {
    ...mapGetters({ project: 'project/current' })
  },
  filters: {
    moment (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  methods: {
    ...mapMutations('project', ['removeProjectDoc']),
    remove (id) {
      this.removeProjectDoc(id)
    }
  }
}
</script>

<style lang="less" scoped>
@import "listProjectDoc";
</style>
