<template>
  <section class="grouped">
    <div class="header">
      <header>Hardware</header>
      <b-button
        v-if="hasAdminPermission"
        v-b-modal="'hardware-selector'"
        class="float-right d-inline-block p-0"
        variant="outline"
      >
        <img :src="require('@/assets/icons/create-icon.svg')">
      </b-button>
    </div>
    <div v-if="project.hardware.length == 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <HardwareBadge
        v-for="hardware in project.hardware" :key="hardware.id"
        :hardware="hardware"
        @remove="remove"
        :dismissible="hasAdminPermission"
      />
    </div>
    <HardwareSelectorModal
      :loadedMembers="project.hardware"
      @confirmed="add"
      @onNewHardware="onNewHardware"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import HardwareBadge from '@/components/Business/Project/Form/Hardware/HardwareBadge/HardwareBadge.vue'
import HardwareSelectorModal from '@/components/Business/Project/Form/Hardware/HardwareSelector/HardwareSelectorModal.vue'

export default {
  name: 'ListHardware',
  components: {
    HardwareBadge,
    HardwareSelectorModal
  },
  props: {
    hasAdminPermission: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({ project: 'project/current' })
  },
  methods: {
    ...mapMutations('project', ['setHardware']),
    add (hardware) {
      this.setHardware(hardware)
    },
    remove (id) {
      this.setHardware(this.project.hardware.filter(h => h.id !== id))
    },
    onNewHardware (modalId) {
      this.$emit('onNewHardware', modalId)
    }
  }
}
</script>

<style lang="less" scoped>
@import "listHardware";
</style>
