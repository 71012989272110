<template>
  <section class="grouped">
    <div class="header">
      <header>No Gestor</header>
      <b-button
        v-if="hasAdminPermission"
        v-b-modal="'non-managers-selector'"
        class="float-right d-inline-block p-0"
        variant="outline">
        <img :src="require('@/assets/icons/create-icon.svg')">
      </b-button>
    </div>
    <div v-if="project.nonManagers.length == 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <EmployeeBadge
        v-for="employee in project.nonManagers" :key="employee.id"
        :employee="employee"
        :dismissible="hasAdminPermission"
        @remove="remove"
      />
    </div>
    <EmployeeSelectorModal
      id="non-managers-selector"
      subtitle="Selecciona las personas a añadir como no gestores"
      :employeesToIgnore="project.managers"
      :loadedMembers="project.nonManagers"
      @confirmed="add"
      @onNewEmployee="onNewEmployee"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import EmployeeBadge from '@/components/Business/Project/Form/Employee/EmployeeBadge/EmployeeBadge.vue'
import EmployeeSelectorModal from '@/components/Business/Project/Form/Employee/EmployeeSelector/EmployeeSelectorModal.vue'

export default {
  name: 'ListNonManagers',
  components: {
    EmployeeBadge,
    EmployeeSelectorModal
  },
  props: {
    hasAdminPermission: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({ project: 'project/current', limboEmployees: 'project/limboEmployees' })
  },
  watch: {
    limboEmployees (newValue, oldValue) {
      if (newValue.length > 0) {
        this.add([...this.project.nonManagers, ...newValue])
      }
    }
  },
  methods: {
    ...mapMutations('project', ['setNonManagers', 'updateTeam']),
    add (nonManagers) {
      this.setNonManagers(nonManagers)
    },
    remove (id) {
      this.setNonManagers(this.project.nonManagers.filter(nonManager => nonManager.id !== id))
      this.removeEmployeeFromTeams(id)
    },
    onNewEmployee (modalId) {
      this.$emit('onNewEmployee', modalId)
    },
    removeEmployeeFromTeams (id) {
      this.project.teams.forEach(team => {
        if (team.members.find(member => member.id === id)) {
          const members = team.members.filter(member => member.id !== id)
          this.updateTeam({ name: team.name, members: members })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "listNonManagers";
</style>
