export function format (date) {
  return date.getFullYear() + '-' +
    lpad(date.getMonth() + 1) + '-' +
    lpad(date.getDate()) + ' ' +
    lpad(date.getHours()) + ':' +
    lpad(date.getMinutes()) + ':' +
    lpad(date.getSeconds())
}

function lpad (value) {
  return String(value).padStart(2, '0')
}
