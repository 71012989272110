<template>
  <section class="grouped">
    <div class="header">
      <header>Sin equipo</header>
    </div>
    <div v-if="unselected.length == 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <EmployeeBadge
        v-for="employee in unselected" :key="employee.id"
        :employee="employee"
        :dismissible=false
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import EmployeeBadge from '@/components/Business/Project/Form/Employee/EmployeeBadge/EmployeeBadge.vue'

export default {
  name: 'ListUnselected',
  components: {
    EmployeeBadge
  },
  computed: {
    ...mapGetters({ unselected: 'project/unselectedEmployees', teamMembers: 'project/teamMembers' })
  }
}
</script>

<style lang="less" scoped>
@import "listUnselected";
</style>
