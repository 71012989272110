<template>
  <modal-component
    :id="id"
    :hide-footer="true"
    title="Crear grupo Telegram"
    size="lg"
    >
   <b-form @submit.stop.prevent>
    <b-row>
      <b-col>
        <span>
          Para crear los grupos en Telegram te pediremos tu número de teléfono asociado a Telegram.
Luego, te pediremos el código de confirmación que te enviará Telegram.
Esta información SOLO se utiliza para crear los grupos de este proyecto y nada más.
        </span>
      </b-col>
    </b-row>
     <b-row>
      <b-col cols="10">
        <b-form-input
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Introduce tu número de teléfono de Telegram e.g. +34123456789"
          :disabled="isSentPhoneNumber"
          v-model="phoneNumber"
        >
        </b-form-input>
      </b-col>
      <b-col>
        <b-button
          :disabled="isSentPhoneNumber"
          @click="startClient"
          variant="primary"
        >
          Enviar
        </b-button>
      </b-col>
     </b-row>
     <b-row v-if="isSentPhoneNumber">
       <b-col  cols="10">
         <b-form-input
           id="phoneNumber"
           name="phoneNumber"
           placeholder="Revisa tu Telegram e introduce el código que has recibido"
           v-model="phoneCode"
          >
         </b-form-input>
       </b-col>
       <b-col>
         <b-button
           @click="sendCode">
           Enviar
         </b-button>
       </b-col>
     </b-row>
   </b-form>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import { StringSession } from 'telegram/sessions'
import { Api, TelegramClient } from 'telegram'

export default {
  name: 'TelegramGroupFormModal',
  components: { ModalComponent },
  props: {
    id: String,
    deleteButton: {
      type: Boolean,
      required: false,
      default: false
    },
    teamName: String
  },
  data () {
    return {
      phoneNumber: '',
      phoneCode: '',
      createdChannel: null,
      isSentPhoneNumber: false,
      isAskingCode: false
    }
  },
  methods: {
    timeout () {
      return new Promise(resolve => setTimeout(resolve, 50))
    },
    async waitForPhoneCode () {
      while (this.isAskingCode === false) await this.timeout()
      // this.isAskingCode = false
      return this.phoneCode
    },
    onDelete () {
      this.$bvModal.show('confirmationModalId')
    },
    onSaveChanges (modalId) {
      this.updateTeam({
        name: this.teamName,
        members: this.team.members,
        groupLink: this.createdChannel
      })
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    sendCode () {
      this.isAskingCode = true
    },
    async startClient () {
      this.isSentPhoneNumber = true
      const telegramSession = this.$store.getters.telegramSession
      const stringSession = new StringSession(telegramSession)
      const apiId = parseInt(process.env.VUE_APP_TELEGRAM_APP_ID, 10)
      const apiHash = process.env.VUE_APP_TELEGRAM_API_HASH
      const client = new TelegramClient(
        stringSession,
        apiId,
        apiHash,
        {
          connectionRetries: 5,
          testServers: true,
          useWSS: true
        }
      )
      try {
        await client.start({
          phoneNumber: () => {
            return this.phoneNumber
          },
          phoneCode: async () => await this.waitForPhoneCode(),
          onError: (err) => console.log(err)
        })
      } catch (e) {
        client.destroy()
      }
      await this.$store.dispatch('updateTelegramSession', client.session.save())
      const groupTitle = `${this.teamName}-${this.$store.getters['project/current'].name}`
      await client.invoke(
        new Api.channels.CreateChannel({
          title: groupTitle,
          about: this.teamName,
          megagroup: true
        })
      )
      const infoChannel = await client.invoke(
        new Api.channels.GetFullChannel({
          channel: groupTitle
        })
      )
      this.createdChannel = infoChannel.fullChat.exportedInvite.link

      await client.invoke(
        new Api.auth.LogOut({})
      )

      this.$emit('onSetGroupLink', this.createdChannel)
      this.$nextTick(() => {
        this.$bvModal.hide(this.id)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "telegramGroupFormModal";
</style>
