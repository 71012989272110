<template>
  <validation-observer ref="projectForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <h6>Información básica</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              vid="name"
              name="nombre"
              rules="required|max:255"
              v-slot="validationContext"
            >
              <b-form-group id="name" label-for="input-name">
                <b-form-input
                  id="name"
                  name="name"
                  :state="getValidationState(validationContext)"
                  placeholder="Nombre"
                  :disabled="!adminPermission"
                  v-model="form.name"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              vid="Venue"
              name="venue"
              rules="max:255"
              v-slot="validationContext"
            >
              <b-form-group id="venue" label-for="venue-name">
                <b-form-input
                  list="venue-list-id"
                  v-model="inputVenue"
                  :state="getValidationState(validationContext)"
                  @focus="hasFocus = true"
                  @blur="hasFocus = false"
                  @change="onChangeVenueInput"
                  placeholder="Venue"
                  :disabled="!adminPermission"

                >
                </b-form-input>
                <b-list-group
                  v-if="showVenueOptions"
                  @mouseenter="listHasFocus = true"
                  @mouseleave="listHasFocus = false"
                  class="venue-options"
                >
                  <b-list-group-item
                    v-for="venue in venueOptions"
                    :key="venue.id"
                    @click="onSelectVenue(venue)"
                    button
                    :disabled="!adminPermission"
                  >
                    {{ venue.name }}
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <v-date-picker
              v-model="dateRange"
              color="indigo"
              is-dark
              is-range
              is-expanded
              locale="ES-es"
              :popover="{ placement: 'auto' }"
              @input="dateSelected"
              mode="dateTime"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-row>
                  <b-col>
                    <validation-provider
                      vid="startDate"
                      name="fecha de inicio"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-group id="eventStartAt" label-for="input-event-start-at">
                        <b-form-input
                          id="startDate"
                          name="start-date"
                          placeholder="Fecha inicio"
                          :state="getValidationState(validationContext)"
                          readonly
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          :disabled="!adminPermission"

                        />
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      vid="endDate"
                      name="fecha de final"
                      rules="required|todayOn"
                      v-slot="validationContext"
                    >
                      <b-form-group id="eventEndAt" label-for="input-event-end-at">
                        <b-form-input
                          id="endDate"
                          name="end-date"
                          placeholder="Fecha final"
                          readonly
                          :state="getValidationState(validationContext)"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          :disabled="!adminPermission"

                        />
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
            </v-date-picker>
          </b-col>
        </b-row>
        <div v-if="form.id">
          <b-row>
            <b-col class="align-self-end">
              <h6>Personal</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ListManagers
                :hasAdminPermission="adminPermission"
                @onNewEmployee="onNewEmployee"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ListNonManagers
                :hasAdminPermission="adminPermission"
                @onNewEmployee="onNewEmployee"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="align-self-end">
              <h6>Equipos</h6>
            </b-col>
            <b-col>
              <b-button
                v-if="adminPermission"
                v-b-modal="'team-create'"
                class="float-right d-inline-block p-0"
                variant="outline">
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ListUnselected />
            </b-col>
          </b-row>
          <b-row v-for="team in project.teams" :key="team.id">
            <b-col>
              <ListTeamMembers
                :teamName="team.name"
                :hasAdminPermission="adminPermission"
                @onNewEmployee="onNewEmployee"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="align-self-end">
              <h6>
                Equipamiento
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ListHardware
                :hasAdminPermission="adminPermission"
                @onNewHardware="onNewHardware"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="align-self-end">
              <h6>Documentación</h6>
            </b-col>
            <b-col>
              <b-button
                v-b-modal="'upload-project-doc'"
                class="float-right d-inline-block p-0"
                variant="outline">
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ListProjectDoc :hasAdminPermission="adminPermission" />
            </b-col>
          </b-row>
        </div>
        <CreateTeamModal />
        <ProjectDocSelectorModal />
      </b-container>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { format as dateFormat } from '@/utils/dateHelper.js'
import ListManagers from '@/components/Business/Project/Form/ListManagers/ListManagers.vue'
import ListNonManagers from '@/components/Business/Project/Form/ListNonManagers/ListNonManagers.vue'
import ListUnselected from '@/components/Business/Project/Form/ListUnselected/ListUnselected.vue'
import CreateTeamModal from '@/components/Business/Project/Form/Team/CreateTeam/CreateTeamModal.vue'
import ListTeamMembers from '@/components/Business/Project/Form/Team/ListTeamMembers/ListTeamMembers.vue'
import ListHardware from '@/components/Business/Project/Form/Hardware/ListHardware/ListHardware.vue'
import ListProjectDoc from '@/components/Business/Project/Form/ProjectDoc/ListProjectDoc/ListProjectDoc.vue'
import ProjectDocSelectorModal from '@/components/Business/Project/Form/ProjectDoc/ProjectDocSelector/ProjectDocSelectorModal.vue'
import Profiles from '@/utils/getProfiles'

export default {
  name: 'ProjectForm',
  components: {
    ListManagers,
    ListNonManagers,
    ListUnselected,
    CreateTeamModal,
    ListTeamMembers,
    ListHardware,
    ListProjectDoc,
    ProjectDocSelectorModal
  },
  props: {
    form_prop: Object
  },
  data () {
    return {
      inputVenue: '',
      hasFocus: false,
      listHasFocus: false,
      form: this.form_prop,
      dateRange: {
        start: this.form_prop.startAt,
        end: this.form_prop.endAt
      }
    }
  },
  watch: {
    form_prop (value) {
      this.form = value
      this.dateRange = {
        start: value.startAt,
        end: value.endAt
      }
      this.load()
    }
  },
  created () {
    this.load()
  },
  computed: {
    ...mapGetters({ project: 'project/current' }),
    ...mapGetters({ venues: 'venues/all' }),
    showVenueOptions () {
      return (this.hasFocus && (this.inputVenue !== '')) || this.listHasFocus
    },
    venueOptions () {
      return this.venues.filter(venue => {
        return venue.name.toLowerCase().includes(this.inputVenue.toLowerCase())
      }).slice(0, 5)
    },
    adminPermission () {
      return this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS ||
      this.form_prop.managers.filter(manager => manager.id === this.$store.state.loggedUser.technician.id).length > 0
    }
  },
  methods: {
    load () {
      if (this.form.venue != null) {
        if (this.venues.length > 0) {
          const venue = this.venues.find(v => v.id === this.form.venue)
          if (venue) {
            this.form.venue = venue
            this.inputVenue = this.form.venue.name
          }
        }
      }
      if (this.form.venueExtraName != null) {
        this.inputVenue = this.form.venueExtraName
      }
      if (this.form.venueExtraName == null && this.form.venue == null) {
        this.inputVenue = ''
      }
    },
    dateSelected (data) {
      this.form.startAt = dateFormat(data.start)
      this.form.endAt = dateFormat(data.end)
    },
    onNewEmployee (id) {
      this.$emit('onNewEmployee', id)
    },
    onNewHardware (id) {
      this.$emit('onNewHardware', id)
    },
    onSelectVenue (selectedVenue) {
      this.inputVenue = selectedVenue.name
      this.form.venueExtraName = this.inputVenue
      this.form.venue = selectedVenue
    },
    onChangeVenueInput () {
      this.form.venueExtraName = this.inputVenue
    }
  }
}
</script>

<style lang="less" scoped>
@import "projectForm";
</style>
