<template>
  <span
    v-if="userEmployee"
    class="employee-badge"
  >
    <b-badge variant="avify" pill>
      {{ userEmployee.firstName }} {{ userEmployee.lastName }}
      <span
        v-if="dismissible"
        @click="$emit('remove', employee.id)"
        class="dismiss-button"
      >
        &times;
      </span>
    </b-badge>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmployeeBadge',
  props: {
    employee: {
      type: Object,
      required: true
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({ employeesPool: 'employee/all' }),
    ...mapGetters({ externalEmployeesPool: 'project/allExternalEmployees' }),
    userEmployee () {
      const employee = this.employeesPool.find(e => e.id === this.employee.userId)
      if (!employee) {
        return this.externalEmployeesPool.find(employee => employee.id === this.employee.userId)
      }
      return employee
    }
  }
}
</script>

<style lang="less">
@import 'employeeBadge.less';
</style>
