<template>
  <modal-component
    :id="id"
    :deleteButton="deleteButton"
    title="Empleados"
    ok-title="Guardar cambios"
    size="lg"
    @confirmed="onSaveChanges"
    @deleted="onDelete"
    @show="onShow()"
  >
    <b-row>
      <b-col>
        <header>
          {{ subtitle }}
        </header>
      </b-col>
    </b-row>
    <b-row class="search-box">
      <b-col class="d-flex justify-content-between align-items-center" lg="7">
        <b-input-group>
          <b-form-input
            id="name"
            name="name"
            placeholder="Escribe el nombre para buscar"
            v-model="filterText"
          ></b-form-input>
          <b-input-group-append is-text>
            <b-icon-search />
          </b-input-group-append>
        </b-input-group>
        <b-button
          size="sm"
          variant="primary"
          class="invite-employee"
           @click="$emit('onNewEmployee', id)"
        >
          Añadir nuevo
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <section class="employee-list">
          <b-row class="title">
            <b-col cols="1"></b-col>
            <b-col>Nombre</b-col>
            <b-col>Interno/Externo</b-col>
            <b-col>Correo electrónico</b-col>
          </b-row>
          <b-row v-if="!optionsList || optionsList.length == 0">
            <b-col class="loading">
              No tienes empleados
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-container>
                <b-row v-for="employee in optionsList" :key="employee.id" class="project-row align-items-center">
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="employee.selected"
                      :value="true"
                      :unchecked-value="false"
                      class="avify-checkbox filter"
                    ></b-form-checkbox>
                  </b-col>
                  <b-col>
                    {{ employee.firstName +" "+ employee.lastName }}
                  </b-col>
                  <b-col>
                    {{ employee.source }}
                  </b-col>
                  <b-col>
                    {{ employee.email }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
    <RemoveConfirmationModal
      id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmedRemove"
      @onCancelRemoveConfirmation="onCancelRemove"
    />
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import { BIconSearch } from 'bootstrap-vue'
import RemoveConfirmationModal from '@/components/Modal/RemoveConfirmationModal'

export default {
  name: 'EmployeeSelectorModal',
  components: {
    ModalComponent,
    BIconSearch,
    RemoveConfirmationModal
  },
  props: {
    id: String,
    subtitle: String,
    loadedMembers: {
      type: Array,
      default: () => []
    },
    employeesToIgnore: {
      type: Array,
      default: () => []
    },
    deleteButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      filterText: '',
      membersList: []
    }
  },
  computed: {
    ...mapGetters({ employeesPool: 'employee/all' }),
    ...mapGetters({ externalEmployeesPool: 'project/allExternalEmployees' }),
    optionsList () {
      if (this.filterText === '') {
        return this.membersList
      }
      return this.membersList.filter(e => {
        return e.firstName.toLowerCase().includes(this.filterText.toLowerCase()) ||
          e.lastName.toLowerCase().includes(this.filterText.toLowerCase()) ||
          e.email.toLowerCase().includes(this.filterText.toLowerCase())
      })
    }
  },
  methods: {
    onShow () {
      this.filterText = ''
      this.membersList = this.selectEmployees(this.depurateEmployees(this.employeesPool))
      this.membersList.forEach(member => {
        member.source = 'Interno'
      })

      const externalMembers = this.selectEmployees(this.depurateEmployees(this.externalEmployeesPool))
      externalMembers.forEach(member => {
        member.source = 'Externo'
      })
      this.membersList = [...this.membersList, ...externalMembers]
    },
    depurateEmployees (list) {
      return list
        .filter(({ id: id1 }) => !this.employeesToIgnore.some(({ userId: id2 }) => id2 === id1))
    },
    selectEmployees (list) {
      return list.map(employee => {
        const isSelected = !!this.loadedMembers.find(lm => lm.userId === employee.id)
        employee.selected = isSelected
        return employee
      })
    },
    onSaveChanges (modalId) {
      const selected = this.membersList
        .filter(e => !!e.selected)
        .map(e => {
          e.technician.userId = e.id
          return e.technician
        })
      this.$emit('confirmed', selected)
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    onDelete () {
      this.$bvModal.show('confirmationModalId')
    },
    onCancelRemove () {
      this.$bvModal.hide('confirmationModalId')
    },
    onConfirmedRemove () {
      this.$emit('delete', this.id)
      this.onCancelRemove()
      this.$bvModal.hide(this.id)
    }
  }
}
</script>

<style lang="less" scoped>
@import 'employeeSelectorModal';
</style>
